// 配置 开发环境、生产环境baseurl地址
const CONFIG = {
  // 测试环境配置
  test: {
    baseUrl: '/api',
    isDev: true
  },
  // 开发环境配置
  development: {
    baseUrl: '/api',//http://192.168.2.152:21000
    isDev: true
  },
  // 生产环境配置
  production: {
    baseUrl: '/api',
    isDev: false
  }
}
export default CONFIG[process.env.VUE_APP_ENV || process.env.NODE_ENV]
