<template>
  <a-range-picker class="may-date" :value="value" :format="props.format" :valueFormat="props.valueFormat" @change="change"/>
</template>
<script setup>
  import { ref, defineProps, defineEmits, watch } from 'vue';
  import dayjs from 'dayjs';
  const props = defineProps({
    modelValue: {},
    begin: {
      type: [String],
      default: ''
    },
    end: {
      type: [String],
      default: ''
    },
    value1: {
      type: [String],
      default: ''
    },
    value2: {
      type: [String],
      default: ''
    },
    valueFormat:{
      type: [String],
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    format:{
      type: [String],
      default: 'YYYY-MM-DD'
    },
  });

  const emits = defineEmits(['update:modelValue', 'update:begin', 'update:end', 'update:value1', 'update:value2', 'change']);
  
  const value = ref([]);
  watch(() => props.modelValue, v => {
    if (v && v.length == 2 && !(v[0] instanceof dayjs)) {
      value.value = [dayjs(v[0], props.format), dayjs(v[1], props.format)];
    }
  }, { immediate: true });

  const setFun = (v, name, i) => {
    if (v === '' || v === null) {
      value.value[i] = undefined;
    }
    if (v && v.length != props.valueFormat.length) {
      emits(`update:${name}`, dayjs(v).format(props.valueFormat));
    }
    if (v && !(v instanceof dayjs)) {
      value.value[i] = dayjs(props[name], props.format);
    }
  }

  watch(() => props.begin, v => { setFun(v, 'begin', 0); }, { immediate: true });
  watch(() => props.end, v => { setFun(v, 'end', 1); }, { immediate: true });
  watch(() => props.value1, v => { setFun(v, 'value1', 0); }, { immediate: true });
  watch(() => props.value2, v => { setFun(v, 'value2', 1); }, { immediate: true });

  const change = (date, dateStr) => {
    let newDateStr = [];
    if (!dateStr[0] && !dateStr[1]) {
      newDateStr = [];
    } else if (props.valueFormat == 'YYYY-MM-DD HH:mm:ss') {
      newDateStr = [dayjs(dateStr[0]).format('YYYY-MM-DD') + ' 00:00:00', dayjs(dateStr[1]).format('YYYY-MM-DD') + ' 23:59:59'];
    } else {
      newDateStr = [dayjs(dateStr[0]).format(props.valueFormat), dayjs(dateStr[1]).format(props.valueFormat)];
    }
    emits('change', date, newDateStr);
    emits('update:modelValue', newDateStr);
    emits('update:begin', newDateStr[0]);
    emits('update:end', newDateStr[1]);
    emits('update:value1', newDateStr[0]);
    emits('update:value2', newDateStr[1]);
  }

</script>
<style scoped>
  .may-date {
    width: 100%;
  }

</style>
