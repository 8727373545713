<template>
  <a-modal :visible="props.modelValue" @update:visible="emits('update:modelValue', $event)" :bodyStyle="{ padding: 0 }" centered>
    <div ref="myModal" class="my-modal-body" :style="newBodyStyle">
      <slot></slot>
    </div>
    <template #footer v-if="Object.keys($slots).some(v => v === 'footer' )">
      <slot name="footer"></slot>
    </template>
  </a-modal>
</template>

<script setup>
  import { ref, defineProps, defineEmits, computed, defineExpose, watch, nextTick } from 'vue';
  const props = defineProps({
    modelValue: {
      type: Boolean
    },
    bodyStyle: {
      type: Object,
      dafault: () => ({})
    }
  });
  const newBodyStyle = computed(() => {
    return Object.assign({ }, props.bodyStyle);
  });
  const emits = defineEmits(['update:modelValue']);
  const myModal = ref();
  watch(() => props.modelValue, v => {
    nextTick(() => {
      myModal.value.scrollTop = 0
    });
  });

  defineExpose({
    myModal
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .my-modal-body {
    padding: 20px;
    height: 100%;
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
</style>
