<template>
  <a-date-picker :value="value" @update:value="emits('update:modelValue', $event)" :format="props.format" :valueFormat="props.valueFormat"/>
</template>
<script setup>
  import { ref, defineProps, defineEmits, watch } from 'vue';
  import dayjs from 'dayjs';
  const props = defineProps({
    modelValue: {},
    valueFormat:{
      type: [String],
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    format:{
      type: [String],
      default: 'YYYY-MM-DD'
    },
  });
  
  const emits = defineEmits(['update:modelValue']);
  const value = ref();
  watch(() => props.modelValue, v => {
    if (!v) {
      value.value = undefined;
    } else if(!(v instanceof dayjs)) {
      value.value = dayjs(v, props.format);
    }
  }, { immediate: true });
</script>
<style scoped>
  .ant-picker{
    width: 100%;
  }
</style>
