<template>
  <my-select ref="mySelectSearchRef" 
             show-search
             :default-active-first-option="false"
             :show-arrow="false"
             :filter-option="false"
             :not-found-content="null"
             :allowClear="false"
             :params="props.params"
             @search="handleSearch">
    <slot></slot>
  </my-select>
</template>

<script setup>
  import { ref, defineExpose, defineProps, useAttrs } from 'vue';
  import mySelect from './index.vue';

  const props = defineProps({
    url: {
      type: [Function],
    },
    params: {
      type: [Object],
      default: () => ({})
    },
  });

  const mySelectSearchRef = ref();
  const init = (...arg) => mySelectSearchRef.value.init(...arg);

  const attrs = useAttrs();

  let timeout;
  function fetch(value) {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      let params = JSON.parse(JSON.stringify(props.params));
      params[attrs.fieldNames?.label || 'label'] = value;
      mySelectSearchRef.value.init(props.url, params);
    }, 500);
  }

  const handleSearch = val => {
    //console.log(val, 'val');
    fetch(val);
  };

  defineExpose({
    init
  });
</script>
