<template>
  <a-tree-select
    :value="props.modelValue" 
    @update:value="emits('update:modelValue', $event)"
    style="width: 100%"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    allow-clear
    tree-default-expand-all
    :tree-data="option ? option : props.treeData">
   <slot></slot>
  </a-tree-select>
</template>

<script setup>
  import { ref, defineProps, defineEmits, defineExpose, watchEffect } from 'vue';
  const props = defineProps({
    modelValue: {},
    url: {
      type: [Function], 
    },
    params: {
      type: [Object],
      default: () => ({})
    },
    onSetOptions: {
      type: [Function],
    },
    treeData: {
      type: [Array],
      default: () => []
    },
    show: {//表单嵌入弹出框时候使用 使得每次打开弹窗时重新调用接口来获取数据
      type: [Boolean],
      default: true,
    }
  });
  const emits = defineEmits(['update:modelValue']);
  //初始化调用接口
  const option = ref(false);
  const init = (fun = props.url, params = props.params) => {
    fun(params).then(res => {
      if (props.onSetOptions) {
        option.value = props.onSetOptions(res);
      } else {
        option.value = res.data.records ? res.data.records : res.data;
      }
    })
  };
  watchEffect(() => {
    props.show && props.url && init(props.url, props.params);
  });

  //向父级抛出的属性 方法
  defineExpose({
    init,
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
