export default {
  required: '${label} 必填!',
  enum: '${label} 必须在下列值 [${enum}] 之中',
  whitespace: '${label} 不能只输入空格',
  types: {
    email: '${label} 请输入或选择正确的邮箱格式!',
    number: '${label} 必须为数字类型!',
    string: '${label} 必须为字符串类型!',
    array: '${label} 必须为数组类型!',
    object: '${label} 必须为对象类型!',
    date: '${label} 必须为日期类型!',
    boolean: '${label} 必须为布尔值类型!',
    integer: '${label} 必须为整数类型!',
    float: '${label} 必须为浮点类型!',
    url: '${label} 请输入或选择正确的url地址格式!',
    hex: '${label} 必须为十六进制类型!',
  },
  string: {
    len: '${label} 长度必须是${len}位',
    min: '${label} 长度必须大于等于${min}',
    max: '${label} 长度必须小于等于${max}',
    range: '${label} 长度必须在 ${min} 到 ${max} 之间',
  },
  number: {
    len: '${label} 只能是${len}位数字',
    min: '${label} 必须大于等于${min}',
    max: '${label} 必须小于等于${max}',
    range: '${label} 必须在 ${min} 到 ${max} 之间',
  },
  array: {
    len: '${label} 子项数量只能是${len}',
    min: '${label} 子项数量必须大于等于${min}',
    max: '${label} 子项数量必须小于等于${max}',
    range: '${label} 子项数量必须在 ${min} 到 ${max} 之间',
  }
}