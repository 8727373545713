<template>
  <div class="my-list">
    <!-- 搜索 -->
    <my-form v-if="props.search.length" ref="searchformRef" :form="props.search" @submit="search" @keyup.enter="searchformRef?.submitLoading?'':searchformRef.submit()" layout="inline">
      <template #default="{ item, form }">
        <slot name="search" :item="item" :form="form"></slot>
      </template>
      <template #footer>
        <a-form-item style="margin-right: 0;">
          <my-button type="search" @click="searchformRef.submit({ checkDateIsChange: false });" :loading="searchformRef?.submitLoading"></my-button>
          <my-button type="rest" @click="searchformRef.resetData();searchformRef.submit({ checkDateIsChange: false });" style="margin-right: 0;"></my-button>
        </a-form-item>
      </template>
    </my-form>
    <!-- 新增 批量删除 导出 导入 等 -->
    <div class="topBtn">
      <my-button type="add" v-if="$route.meta[`${getModule}add`]&&props.api.save" @click="props.api.addFun ? props.api.addFun({ callback: initFormObj }) : initForm()"></my-button>
      <my-button type="import" v-if="$route.meta[`${getModule}import`]&&props.api.import" @click="visibleImport = true"></my-button>
      <my-button type="export" v-if="$route.meta[`${getModule}export`]&&props.api.export" @click="visibleExport = true;nextTick(() => exportFormRef.initData({}, 'add'))"></my-button>
      <slot name="topBtn"></slot>
      <my-button type="delete" v-if="$route.meta[`${getModule}batchDelete`]&&props.api.batchDelete" :disabled="!selectedRowKeys.length" @click="deleteFun()"></my-button>
      <span style="float: right;display: none;">
        <a-popover trigger="hover" placement="bottom">
          <template #content>
            <div v-for="(item, i) in columns" :key="'showColumn'+i">
              <a-checkbox v-model:checked="item.show" v-if="item.key!='action'">{{ item.title }}</a-checkbox>
            </div>
          </template>
          <a-button type="link"><eye-outlined /></a-button>
        </a-popover>
      </span>
    </div>
    <!-- 表格 -->
    <a-config-provider :component-size="componentSize" :locale="zhCN">
      <a-table v-if="props.api && props.api.page"
               :dataSource="dataSource.length?dataSource:props.dataSource"
               :columns="columns.filter(v=>v.show)"
               :pagination="hasPagination ? pagination : false"
               :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectRow, onSelect: rowSelect, hideSelectAll: $attrs.rowSelectRadio, ...($attrs.rowSelection || {}) }"
               rowKey="id"
               :scroll="{ x: '100%', y: `calc(100vh - 427px)` }"
               :loading="tableLoading"
               @resizeColumn="(width, col) => col.width = width"
               style="width: 100%;"
               v-bind="tableConfig">
        <template #bodyCell="{ column, record, index }">
          <template v-if="column.key === 'index' || column.dataIndex === 'index'">
            <span>
              {{ (pagination.current - 1) * pagination.pageSize +  index + 1 }}
            </span>
          </template>
          <template v-else-if="column.type === 'img'">
            <a-image-preview-group v-if="record[column.key || column.dataIndex]">
              <template v-if="typeof(record[column.key || column.dataIndex])=='string'&&!column.setImgs">
                <a-image :width="60" :src="record[column.key || column.dataIndex]" style="object-fit:cover;" />
              </template>
              <template v-else>
                <div style="height:60px;width:60px;">
                  <a-image v-for="(img, imgIndex) in column.setImgs ? column.setImgs(record[column.key || column.dataIndex]) : record[column.key || column.dataIndex]" :width="60" :height="60" :src="img" />
                </div>
              </template>
            </a-image-preview-group>
          </template>
          <template v-else-if="column.type === 'time' || column.type === 'timeHMS'">
            {{ dateFormat(record[column.key || column.dataIndex], column.type === 'timeHMS' ? 'YYYY/MM/DD HH:mm:ss' : column.format) }}
          </template>
          <template v-else-if="column.type === 'empty'">
            {{ record[column.key || column.dataIndex] || column.default || '- -' }}
          </template>
          <template v-else-if="column.type === 'money'">
            <div style="display: inline-block; width: 100%; text-align: right">
              {{ record[column.key || column.dataIndex] }}
            </div>
          </template>
          <template v-else-if="column.key === 'action' || column.dataIndex === 'action'">
            <div style="white-space: nowrap;">
              <my-button type="link" v-if="$route.meta[`${getModule}approval`]&&props.api.approval&&record.hasAudit&&!props.api.hideApproval&&(!props.api.custom || props.api.custom('approval', record)) !== false" @click="initForm('approval', record)" size="small">审批</my-button>
              <my-button type="link" v-if="$route.meta[`${getModule}update`]&&props.api.update&&!props.api.hideUpdate&&(!props.api.custom || props.api.custom('update', record) !== false)" @click="initForm('update', record)" size="small">编辑</my-button>
              <my-button type="link" v-if="props.api.pageByID" @click="initForm('preview', record)" size="small">查看</my-button>
              <my-button type="link" v-if="$route.meta[`${getModule}upload`]&&props.api.bizName" @click="$refs.dxUploadRef.open(record.id)" size="small">上传</my-button>
              <my-button type="link" v-if="$route.meta[`${getModule}download`]&&props.api.bizName" @click="$refs.dxDownloadRef.open(record.id)" size="small">下载</my-button>
              <slot name="action" :column="column" :record="record" :index="index"></slot>
              <my-button type="link" v-if="$route.meta[`${getModule}delete`]&&props.api.batchDelete&&!props.api.hideBatchDelete&&(!props.api.custom || props.api.custom('delete', record)) !== false" @click="deleteFun(record.id)" size="small" danger>删除</my-button>
            </div>
          </template>
          <slot name="table" :column="column" :record="record" :index="index"></slot>
        </template>
      </a-table>
    </a-config-provider>
    <!-- 表单 -->
    <component :is="$attrs.modalType=='drawer'?'my-drawer':'my-modal'" 
               :maskClosable="$attrs.modalType == 'drawer' ? true : false"
               :title="`${$route.meta.title} ${FormTitle}`" 
               v-model="visibleForm" 
               :bodyStyle="{ paddingRight: '58px' }" 
               :width="props.modalWidth" 
               @cancel="formClose"
               @close="formClose">
      <a-spin :spinning="spinningForm" tip="加载中...">
        <my-form ref="formRef" :form="form" @submit="submit" :show="visibleForm" :showSubmit="false" :whiteList="$attrs.whiteList || ['id']" :openType="openType" :title="FormTitle" :width="props.modalWidth">
          <template #default="{ item, form, show, disabled, openType }">
            <slot name="form" :item="item" :form="form" :show="show" :disabled="disabled" :openType="openType"></slot>
          </template>
        </my-form>
      </a-spin>
      <template #footer>
        <div class="modalFooter">
          <my-button v-if="props.api.draft && (openType=='add' || (openType=='update' && formRef?.formList?.auditStatus == 3))" @click="formRef.submit(true, false)" :loading="formRef?.submitLoading">保存</my-button>
          <my-button v-if="openType!='preview'" @click="formRef.submit({ submitAllParams: $attrs.whiteList === false || (openType!='update' && openType!='approval'), checkDateIsChange: $attrs.checkDateIsChange === false ? false : true })" :loading="formRef?.submitLoading">提交</my-button>
          <my-button v-if="openType!='preview'&&openType!='approval'&&openType!='update'" type="rest" @click="formDataRest()"></my-button>
        </div>
      </template>
    </component>
    <!-- 导出 -->
    <my-modal title="导出" v-model="visibleExport" :bodyStyle="{ paddingRight: '58px' }" :width="700">
      <my-form v-if="props.export.length" ref="exportFormRef" :form="props.export" @submit="exportFun">
        <template #default="{ item, form, show, disabled }">
          <slot name="export" :item="item" :form="form" :show="show" :disabled="disabled"></slot>
        </template>
        <template #footer>
          <span></span>
        </template>
      </my-form>
      <template #footer>
        <div class="modalFooter">
          <my-button :loading="exportFormRef?.submitLoading" @click="exportFormRef.submit({ submitAllParams: true, checkDateIsChange: false })">确定导出</my-button>
          <my-button @click="visibleExport = false;exportFormRef.resetData();exportFormRef.clearValidate()">取消</my-button>
        </div>
      </template>
    </my-modal>
    <!-- 导入 -->
    <my-modal title="导入" v-model="visibleImport" :bodyStyle="{ paddingRight: '58px' }" width="80%">
      <a-spin :spinning="spinningImport" tip="加载中...">
        <div style="padding-bottom: 20px;">
          <input ref="importFile" type="file" style="display:none;" @change="importPreview" accept=".xls,.xlsx" />
          <my-button @click="$refs.importFile.click()">选择导入文件</my-button>
          <a v-if="props.api.importTemplate" :href="props.api.importTemplate" target="_blank" style="margin-left: 20px;">下载{{props.api.importTemplateName}}模板</a>
        </div>
        <div class="formItemTitle">
          <div class="columnIcon"></div>
          可导入的数据
        </div>
        <a-table size="middle"
                 :dataSource="importData.successList || []"
                 :columns="importSuccessColumns"
                 rowKey="index"
                 :scroll="{ x: '100%', y: `calc(100vh - 500px)` }"
                 style="width: 100%;"></a-table>
        <div class="formItemTitle">
          <div class="columnIcon"></div>
          导入异常的数据
        </div>
        <a-table size="middle"
                 :dataSource="importData.errorList || []"
                 :columns="importErrorColumns"
                 rowKey="index"
                 :scroll="{ x: '100%', y: `calc(100vh - 500px)` }"
                 style="width: 100%;"></a-table>
      </a-spin>
      <template #footer>
        <div class="modalFooter">
          <my-button :loading="importLoading" @click="importFun()">确定导入</my-button>
          <my-button @click="importData={};$refs.importFile.click();">重新导入</my-button>
        </div>
      </template>
    </my-modal>
    <!-- 上传 -->
    <dx-upload ref="dxUploadRef" :bizName="props.api.bizName" />
    <!-- 下载 -->
    <dx-download ref="dxDownloadRef" :bizName="props.api.bizName" />
  </div>
</template>

<script>
  // declare additional options
  export default {
    inheritAttrs: false,
  }
</script>
<script setup>
  import { ref, reactive, onMounted, onUnmounted, defineProps, defineExpose, defineEmits, watchEffect, watch, isReactive, nextTick, computed, useAttrs } from 'vue';
  import { message, Modal } from 'ant-design-vue';
  import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons-vue";
  import { dateFormat } from '@/utils';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dxUpload from '@/components/attachment/dx-upload'
  import dxDownload from '@/components/attachment/dx-download'
  import { lookPageRecord, savePageRecord } from '@/api/record/record'
  import { useRouter } from 'vue-router';
  const router = useRouter();

  const props = defineProps({
    search: {
      type: Array,
      default: () => []
    },
    form: {
      type: Array,
      default: () => []
    },
    export: {
      type: Array,
      default: () => []
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    importColumns: {
      type: Array,
      default: () => []
    },
    importErrorColumns: {
      type: Array,
      default: () => []
    },
    api: {
      type: Object,
      default: () => ({})
    },
    tableConfig: {
      type: Object,
      default: () => ({})
    },
    modalWidth: {
      type: [Number, String],
      default: 700
    },
    onBeforeFormInit: {
      type: Function,
      default: () => Promise.resolve()
    },//打开表单前
    onBeforeSubmit: {
      type: Function,
      default: () => Promise.resolve()
    },//提交表单前
    onBeforeFormClose: {
      type: Function,
      default: () => Promise.resolve()
    },//关闭表单前
  });

  const emits = defineEmits(['init', 'afterFormInit', 'formInit', 'afterSubmit']);

  const attrs = useAttrs();

  const getModule = computed(() => props.api.module ? `${props.api.module}_` : '');

  //查询和表单ref对象
  const searchformRef = ref();
  const formRef = ref();

  const componentSize = ref('middle');
  const resizeFun = () => componentSize.value = window.innerWidth > 1600 ? 'middle' : 'small';
  //初始化方法
  onMounted(() => {
    resizeFun();
    window.addEventListener('resize', resizeFun);
    if (searchformRef.value) {
      searchformRef.value.initData();
    }
    search({});
  });
  //销毁事件
  onUnmounted(() => {
    window.removeEventListener('resize', resizeFun);
  });

  //表头数据
  const columns = ref([]);
  const importSuccessColumns = ref([]);
  const importErrorColumns = ref([]);
  watchEffect(() => {
    // columns.value = props.columns.map(column => Object.assign({ ellipsis: true, width: 150, minWidth: 100, show: true, resizable: true, align: 'center' }, column));
    columns.value = props.columns.map(column => Object.assign({ ellipsis: true, minWidth: 80, show: true, resizable: true, align: 'left' }, column));
    if (props.importColumns.length) {
      importSuccessColumns.value = [...props.importColumns];
      importErrorColumns.value = [...props.importColumns, { title: '异常原因', dataIndex: 'errorMessage', key: 'errorMessage', ellipsis: true, width: 150 }];
    }
    if (props.importErrorColumns.length) {
      importErrorColumns.value = [...props.importErrorColumns];
    }
    if (props.api.import && !props.importErrorColumns.length && !props.importColumns.length) {
      importSuccessColumns.value = props.columns.map(column => Object.assign({ ellipsis: true, width: 150, show: true }, column));
      importErrorColumns.value = [...importSuccessColumns.value, { title: '异常原因', dataIndex: 'errorMessage', key: 'errorMessage', ellipsis: true, width: 150 }];
    }
    if (props.columns[0] && props.columns[0].key == 'index') {//如果第一列是index 给默认宽度
      !props.columns[0].width && (columns.value[0].width = 60);
      columns.value[0].minWidth = 60;
      columns.value[0].fixed = 'left';
    }
    const last = props.columns[props.columns.length - 1];
    if (last && last.key == 'action') {//如果最后一列是操作栏 给默认宽度
      columns.value[columns.value.length - 1].width = last.width || 200;
      columns.value[columns.value.length - 1].fixed = 'right';
      columns.value[columns.value.length - 1].ellipsis = false;
    }
  });

  //查询方法
  const dataSource = ref([]);
  const tableLoading = ref(false);
  const exportParams = ref({});//导出用 记录搜索的条件
  const search = async ({ data = searchformRef.value?.formList || {}, reset = null, current = 1 }) => {
    if (!props?.api?.page) {
      return;
    }
    let pageData;
    try {
      pageData = await lookPageRecord({routeAddress:router.currentRoute.value.fullPath});
    } catch (error) {
      message.error(error?.msg || `获取数据失败，请稍后重试`);
      tableLoading.value = false;
      return;
    }
    pagination.pageSize = pageData.data ? Number(pageData.data.pageSize) : 10;
    exportParams.value = JSON.parse(JSON.stringify(data));
    tableLoading.value = true;
    pagination.current = current;
    const searchData = Object.assign({ current: pagination.current, size: pagination.pageSize }, data);
    Object.keys(searchData).map(key => {
      !searchData[key] && searchData[key] !== 0 && (delete searchData[key]);//删除空字符串的参数
    });
    props.api.page(searchData).then(res => {
      if (pagination.current != 1 && res.data.records && !res.data.records.length) {//在搜索框输入内容后 点击翻页 导致出现 例如只有第一页有数据 当前在第二页 出现空白
        search({});
        return;
      }
      if (res.data && res.data.records) {
        hasPagination.value = true;
        dataSource.value = res.data.records;
        pagination.total = res.data.total * 1;
      } else if (Array.isArray(res.data)) {
        hasPagination.value = false;
        dataSource.value = res.data;
        pagination.total = res.data.length;
      }
    }).catch((err) => {
      message.error(err?.msg || `获取数据失败，请稍后重试`);
    }).finally(() => {
      tableLoading.value = false;
      reset && reset(false);
    });
  };
  //分页参数
  let goPageOne = false;
  const hasPagination = ref(true);
  const pagination = reactive({
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: (total) => `共${total}条记录`,
    showSizeChanger: true,
    showQuickJumper: true,
    size: 'small',
    //hideOnSinglePage: true,
    onChange: async(current, pageSize) => {
      //console.log('change', current, pageSize);
      if (!goPageOne) {
        pagination.current = current;
      } else {
        goPageOne = false;
        pagination.current = 1;
      }
      pagination.pageSize = pageSize;
      await savePageRecord({ routeAddress: router.currentRoute.value.fullPath, pageSize})
      search({ current: pagination.current });
    },
    onShowSizeChange: (current, pageSize) => {
      //console.log('showSizeChange', current, pageSize);
      goPageOne = true;
      pagination.pageSize = pageSize;
    }
  });
  //表格勾选列
  const selectedRowKeys = ref([]);
  const selectedRows = ref([]);
  const onSelectRow = (keys, rows) => {
    if (!attrs.rowSelectRadio) {
      selectedRows.value = rows;
      selectedRowKeys.value = keys;
    }
  };
  const rowSelect = record => {
    if (!attrs.rowSelectRadio) {
      return;
    }
    if (selectedRowKeys.value[0] != record.id) {
      selectedRows.value = [record];
      selectedRowKeys.value = [record.id];
    } else {
      selectedRows.value = [];
      selectedRowKeys.value = [];
    }
  };

  //删除方法
  const deleteFun = (id) => {
    Modal.confirm({
      title: '提示',
      content: props.api.deleteContent || '确认删除！',
      onOk() {
        props.api.batchDelete(id ? [id] : selectedRowKeys.value).then(res => {
          message.success('删除成功');
          search({ current: dataSource.value.length == selectedRowKeys.value.length ? 1 : pagination.current });
          selectedRowKeys.value = [];
        }).catch(err => {
          message.error(err?.msg || `删除失败`);
        });
      },
    });
  };

  //初始化表单
  const visibleForm = ref(false);
  const spinningForm = ref(false);
  const FormTitle = ref('新增');
  const form = isReactive(props.form) ? props.form : reactive(props.form);
  const openType = ref('');
  let submitFun = null;
  const initForm = (type = 'add', record = {}, fun = null, title = '') => {
    submitFun = fun;
    openType.value = type;
    visibleForm.value = true;
    spinningForm.value = true;
    nextTick(() => {
      (props.onBeforeFormInit({ data: record, type }) || Promise.resolve()).then(() => {
        FormTitle.value = title || { add: '新增', update: '编辑', preview: '查看', approval: '审批' }[type];
        if (props.api?.pageByID && (type == 'update' || type == 'preview' || type == 'approval')) {
          props.api.pageByID(record).then(res => {
            nextTick(() => {
              emits('formInit', { type, data: res.data, title });
              formRef.value.initData(res.data, type);
              emits('afterFormInit', { type, show: formRef.value.show, disabled: formRef.value.disabled, data: formRef.value.formList, title });
              spinningForm.value = false;
            });
          }).catch(() => {
            message.error(`获取数据失败，请稍后重试`);
            visibleForm.value = false;
            spinningForm.value = false;
          });
        } else {
          nextTick(() => {
            emits('formInit', { type, data: record, title });
            const cloneData = JSON.parse(JSON.stringify(record));//防止元数据被改变
            formRef.value.initData(cloneData, type);
            emits('afterFormInit', { type, show: formRef.value.show, disabled: formRef.value.disabled, data: formRef.value.formList, title });
            spinningForm.value = false;
          });
        }
      }).catch(() => {
        visibleForm.value = false;
        spinningForm.value = false;
      });
    });
  };
  const initFormObj = (...arg) => {
    if (typeof arg[0] == 'object') {
      let param = arg[0];
      initForm(param.type, param.data, param.fun, param.title);
    } else {
      initForm(...arg);
    }
  }
  //表单点击遮罩层或左上角叉或取消按钮的回调
  const formClose = e => {
    visibleForm.value = true;
    const cloneData = JSON.parse(JSON.stringify(formRef.value?.formList));//防止提交失败 但是元数据已被改变
    (props.onBeforeFormClose({ type: openType.value, data: cloneData, title: FormTitle.value }) || Promise.resolve()).then(res => {
      visibleForm.value = res === false;
    });
  }

  //新增 编辑 等
  const submit = ({ data, type, reset }) => {
    type = type || openType.value;
    const cloneData = JSON.parse(JSON.stringify(data));//防止提交失败 但是元数据已被改变
    (props.onBeforeSubmit({ type, data: cloneData }) || Promise.resolve(cloneData)).then(newData => {
      const fun = submitFun || { add: props.api.save, update: props.api.update, approval: props.api.approval, draft: props.api.draft }[type];
      fun(newData || cloneData).then(res => {
        message.success(`${type == 'draft' ? '保存' : FormTitle.value}成功`);
        search({ current: type == 'approval' ? pagination.current : 1 });
        setTimeout(() => { visibleForm.value = false; }, 100);
        setTimeout(() => { reset && reset(); }, 500);
        emits('afterSubmit', openType.value);
      }).catch(err => {
        let detailMsg = Array.isArray(err.detailMsg) ? err.detailMsg[0] : err.detailMsg;
        message.error(detailMsg || err?.msg || `${type == 'draft' ? '保存' : FormTitle.value}失败`);
        reset && reset(false);
      });
    }).catch((e) => {
      console.log('onBeforeSubmit:catch');
      console.log(e);
      reset(false);
    });
  };
  //表单重置
  const formDataRest = () => {
    if (props.api.restFun) {
      props.api.restFun({ data: formRef.value.formList })
    } else {
      formRef.value.resetData();
      formRef.value.clearValidate();
    }
  }
  //导出
  const visibleExport = ref(false);
  const exportFormRef = ref();
  const exportFun = ({ data, type, reset }) => {
    Object.keys(data).map(key => {
      !data[key] && data[key] !== 0 && (delete data[key]);//删除空字符串的参数
    });
    props.api.export(data).then(res => {
      visibleExport.value = false;
      if (res.headers['content-disposition']) {
        message.success(`导出成功`);
        //获取文件名称与文件类型
        const filename = decodeURI(res.headers['content-disposition'].split('filename=')[1].split('.')[0]);
        const contentType = res.headers['content-type'];
        const url = window.URL.createObjectURL(new Blob([res.data], {
          type: contentType
        }));
        const link = document.createElement('a');
        link.style.display = "none";
        link.download = filename;
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        reset && reset();
      } else {
        const reader = new FileReader()
        reader.onload = async () => {
          const err = JSON.parse(reader.result);
          message.error(err?.msg || `导出失败`);
          reset && reset(false);
        }
        reader.readAsText(res.data);
      }
    }).catch(err => {
      message.error(err?.msg || `导出失败`);
      reset && reset(false);
    }).finally(() => {
      exportFormRef.value.resetData();
    });
    /*
    Modal.confirm({
      title: '提示',
      content: `将导出已查询出的数据！`,
      onOk() {
        props.api.export(exportParams.value).then(res => {
          message.success(`导出成功`);
        }).catch(() => {
          message.error(`导出失败`);
        });
      }
    });
    */
  };
  //导入
  const visibleImport = ref(false);
  const spinningImport = ref(false);//加载数据loading
  const importLoading = ref(false);//提交按钮的loading
  const importData = ref({ successList: [], errorList: [] });
  const importFile = ref(null);
  watch(visibleImport, (v) => {
    v && (importData.value = { successList: [], errorList: [] });
  })
  const importPreview = (e) => {
    //console.log(e.target.files[0]);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    spinningImport.value = true;
    props.api.importPreview(formData).then(res => {
      importData.value = res.data;
    }).finally(() => {
      importFile.value.value = '';
      spinningImport.value = false;
    });;
  }
  const importFun = () => {
    if (!importData.value.successList?.length) {
      message.error(`无可导入数据`);
      return;
    }
    importLoading.value = true;
    props.api.import(importData.value.successList).then(res => {
      visibleImport.value = false;
      message.success(`导入成功`);
      searchformRef.value.resetData();
      search({}); 
    }).catch(err => {
      message.error(err?.msg || `导入失败`);
    }).finally(() => {
      importLoading.value = false;
    });
  }

  const quick = ({ data = {}, tip = '操作', msg = '操作', type = 'update', fun }, callback) => {
    Modal.confirm({
      title: '提示',
      content: tip || `确认${msg}！`,
      onOk() {
        (fun || { add: props.api.save, update: props.api.update }[type])(data).then(res => {
          if (callback) {
            callback(true);
          } else {
            message.success(`${msg}成功`);
            search({});
          }
        }).catch((err) => {
          message.error(err.msg || `${msg}失败`);
        });
      },
      onCancel() {
        if (callback) {
          callback(false);
        } else {
          search({});
        }
      }
    });
  }

  // 上传
  const dxUploadRef = ref();
  // 下载
  const dxDownloadRef = ref();


  //向父级抛出的属性 方法
  defineExpose({
    search,
    selectedRowKeys,//表格勾选的id集合
    selectedRows,//表格勾选的行集合
    initForm: initFormObj,//手动调用新增和编辑
    quick,//快捷调用编辑接口 例如启用禁用
    dataSource,//表格数据
    formRef,
    tableLoading,
    exportFormRef,
    searchformRef,
    exportParams,
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .my-list {
    flex: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    .ant-table-wrapper{
      flex: 1;
      ::v-deep .ant-spin-nested-loading{
        height: 100%;
        .ant-spin-container{
          height: 100%;
          display: flex;
          flex-direction: column;
          .ant-table{
            flex: 1;
          }
        }
      }
    }
  }

  .topBtn {
    padding: 10px 0;
  }

  .formItemTitle {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    background-color: rgba(24, 144, 255, 0.1);
    margin-right: -30px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
  }

  .columnIcon {
    display: inline-block;
    width: 4px;
    height: 20px;
    border-radius: 3px;
    background-color: #1890ff;
    margin-right: 5px;
  }
  .modalFooter {
    text-align: center;
  }
</style>
