import request from '@/utils/request'
export function savePageRecord (data) {
  return request({
    url: '/sys-menu-access-history/save-access-history',
    method: 'post',
    data: data
  })
}

export function lookPageRecord (data) {
  return request({
    url: '/sys-menu-access-history/query-access-history',
    method: 'post',
    data: data
  })
}