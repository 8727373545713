<template>
  <a-radio-group :value="props.modelValue" @update:value="emits('update:modelValue', $event)" :options="option?option:setOptions(props.options)">
    <slot></slot>
  </a-radio-group>
</template>

<script setup>
  import { ref, defineProps, defineEmits, defineExpose, watchEffect } from 'vue';
  const props = defineProps({
    modelValue: {},
    url: {
      type: [Function], 
    },
    params: {
      type: [Object],
      default: () => ({})
    },
    onSetOptions: {
      type: [Function],
    },
    options: {
      type: [Array],
      default: () => []
    },
    show: {//表单嵌入弹出框时候使用 使得每次打开弹窗时重新调用接口来获取数据
      type: [Boolean],
      default: true,
    },
    fieldNames:{
      type: [Object],
      default: () => ({ label: 'label', value: 'value' })
    },
  });
  const emits = defineEmits(['update:modelValue']);
  //初始化调用接口
  const option = ref(false);
  const setOptions = list => {
    if (list.length && typeof (list[0]) == 'object') {
      return list.map(v => (Object.assign({ label: v[props.fieldNames.label], value: v[props.fieldNames.value] }, v)));
    }
    return list;
  }
  const init = (fun = props.url, params = props.params) => {
    fun(params).then(res => {
      if (props.onSetOptions) {
        option.value = props.onSetOptions(res);
      } else {
        option.value = setOptions(res.data.records ? res.data.records : res.data);
      }
    })
  };
  watchEffect(() => {
    props.show && props.url && init(props.url, props.params);
  });

  //向父级抛出的属性 方法
  defineExpose({
    init,
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
