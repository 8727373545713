/* myMsgBox.js */
import myForm from "./index.vue";
import { h, ref, createApp } from "vue";
import Ant from 'ant-design-vue';

export function myMsgBox(text, title, options) {
  return new Promise((resolve, reject) => {
    // 保存组件实例
    let myFormRef = ref();
    // 创建容器
    const mountNode = document.createElement("div");
    // 将容器插入到body内
    document.body.appendChild(mountNode);
    // 创建节点
    const app = createApp({
      render() {
        // 这里使用了h()函数,等价于<myForm :title="title" :text="text" :options="options" @submit="..." @onClosed="..."></myForm>
        return h(myForm, {
          ref: myFormRef,
          // 参数
          // 配置项
          options: options,
          // 事件
          onSubmit: (data) => {
            resolve(data);
          },
          onClosed: () => {
            // 这里将容器给清除掉( 至于是否还存在其他内存泄漏，就不太清楚了 )
            setTimeout(() => {
              mountNode.remove();
            }, 500);
            reject();
          },
        });
      },
    });
    app.use(Ant);
    // 挂载容器，instance就是容器的实例
    let instance = app.mount(mountNode);
    // 打开弹窗
    myFormRef.value.openModel();
  }).catch(()=>{});
}
