import { login, getMenu } from '@/api/login'
import { userInfo } from '@/api/user/user'
import { setToken, setUserInfo } from '@/utils'

const user = {
  state: {
    token: "",
    name: "管理员",
    welcome: "",
    avatar: "",
    roles: [],
    info: {},
    router: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_ROUTER: (state, router) => {
      state.router = router;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then((response) => {
          console.log(response)
          const token = response.data.token;
          setToken(token);
          commit("SET_TOKEN", token);
          commit("SET_INFO", response.data);
          setUserInfo(response.data);
          resolve();
        }).catch((error) => {
          reject(error); 
        });
      });
    },

    // 获取菜单
    GetMenu({ commit }) {
      return new Promise((resolve, reject) => {
        getMenu({}).then((response) => {
          commit("SET_ROUTER", response.data);
          localStorage.setItem('router', JSON.stringify(response.data));
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    // 获取用户信息
    GetUserInfo({ commit }){
      userInfo().then((res)=>{
        commit("SET_INFO", res.data);
        setUserInfo(res.data);
      }).catch(e=>{
        console.log(e);
      })
    },

    // 登出
    Logout({ commit }) {
      commit("SET_TOKEN", "");
      commit("SET_ROUTER", []);
      localStorage.setItem('router', '[]');
      setToken("");
      setTimeout(() => {
        window.location.reload();
      }, 300);
    },
  },
};

export default user
