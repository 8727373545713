<template>
  <div v-if="$attrs.disabled && !fileList.length">无</div>
  <div class="my-upload" v-else>
    <a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleRemove" @preview="handlePreview" :multiple="props.max>1" :accept="props.accept" v-bind="$attrs">
      <template v-if="fileList.length < props.max && !$attrs.disabled">
        <div v-if="$attrs['list-type']=='picture-card'">
          <plus-outlined />
          <div style="margin-top: 8px">选择图片</div>
        </div>
        <a-button v-else>
          <upload-outlined></upload-outlined>
          上传附件
        </a-button>
      </template>
    </a-upload>
    <slot>
      <my-button v-if="sumbitButton"
                 :disabled="!uploadReady"
                 :loading="uploading"
                 style="margin-top: 16px"
                 @click="handleUpload">
        {{ uploading ? '上传中' : '开始上传' }}
      </my-button>
      <div v-else style="height: 20px;">
        <a-button v-show="uploading" type="link"><loading-outlined />上传中</a-button>
      </div>
    </slot>
    <div style="display:none;">
      <a-image-preview-group :key="imagePreviewKey" :preview="{ visible, onVisibleChange: vis => (visible = vis), current }">
        <a-image v-for="(item, i) in fileList" :src="item.url" :key="i" />
      </a-image-preview-group>
    </div>
  </div>
</template>

<script>
  // declare additional options
  export default {
    inheritAttrs: false,
  }
</script>
<script setup>
  import { ref, defineProps, defineEmits, defineExpose, watchEffect, computed, useAttrs, watch } from 'vue';
  import { upload } from '@/api/public';
  import { UploadOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
  import { message } from 'ant-design-vue';
  import { regExpMap } from '@/utils';
  import { subitemType, accept } from './config.js'
  const props = defineProps({
    modelValue: {},
    upload: {
      type: Function,
    },
    data: {
      type: Object,
      default: () => ({})
    },
    immediately: {//立即上传
      type: [Boolean],
      default: false,
    },
    max: {
      type: [Number],
      default: 8,
    },
    show: {
      type: [Boolean],
      default: true,
    },
    sumbitButton: {
      type: [Boolean],
      default: false,
    },
    subitemType: {
      type: String,
      default: subitemType
    },
    accept: {
      type: String,
      default: accept
    },
  });
  const emits = defineEmits(['update:modelValue', 'afterUpload', 'afterDelete']);
  const attrs = useAttrs();
  const strToObj = list => list.map(v => typeof (v) == 'string' ? { url: v, name: v } : v);
  const objToStr = list => list.map(v => props.subitemType == 'object' ? v : v.url);

  const fileList = ref([]);
  const stopUpdate = ref(false);

  watchEffect(() => {
    props.show && (fileList.value = []);
  });

  watch(() => props.modelValue, (v, old) => {
    if (!!v && !!old && v.length === old.length && v.length === 0) {
      return;
    }
    if (stopUpdate.value) {
      stopUpdate.value = false;
      return;
    }
    if (!props.modelValue) {
      fileList.value = [];
      return;
    }
    const noFileList = fileList.value.filter(v => !(v instanceof File));
    //防止已上传的列表出现刷新动画
    if (noFileList.length != props.modelValue.length) {
      fileList.value = strToObj([...props.modelValue, ...fileList.value.filter(v => v instanceof File)]);
    } else if (props.modelValue.some((v, i) => v != fileList.value[i].url)) {
      fileList.value = strToObj(props.modelValue);
    }
    if (fileList.value.length > props.max) {
      fileList.value = fileList.value.slice(0, props.max);
    }
  }, { deep: true, immediate: true });


  const uploadReady = computed(() => fileList.value.some(v => v instanceof File));
  const imagePreviewKey = ref(new Date().getTime());//动态删除图片预览组件的子项 预览任然会出现已删除的图片 所以需要重新渲染该组件来解决bug
  const handleRemove = file => {
    const index = fileList.value.indexOf(file);
    const newFileList = fileList.value.slice();
    newFileList.splice(index, 1);
    fileList.value = newFileList;
    imagePreviewKey.value = new Date().getTime();
    if (!(file instanceof File)) {
      stopUpdate.value = true;
      emits('update:modelValue', objToStr(newFileList.filter(v => !(v instanceof File))));
      emits('afterDelete', file);
    }
  };

  let maxErrFlag = true;
  let immediatelyTimeout = null;
  const beforeUpload = file => {
    if (attrs['list-type'] == 'picture-card' && !regExpMap.get('isImg').test(file.type)) {
      message.error('请选择正确的图片格式');
      return false;
    }
    if (attrs['list-type'] !== 'picture-card' && props.accept && !new RegExp(`.(${props.accept.replace(/,/g, '|').replace(/image\/\*/g, 'png|jpg|gif|jpeg|webp').replace(/image\//g, '')})$`).test(file.type + file.name)) {
      message.error('只能上传： ' + props.accept.replace(/image\/\*,/g, '图片,').replace(/,image\/\*/g, ',图片').replace(/image\//g, '').replace(/,/g, '或') + '格式的文件');
      return false;
    }
    if (fileList.value.length == props.max) {
      maxErrFlag && message.error(`最多只能上传${props.max}项`);
      maxErrFlag = false
      setTimeout(() => maxErrFlag = true, 500);
      return false;
    }
    fileList.value = [...fileList.value, file];
    if (regExpMap.get('isImg').test(file.type)) {
      const r = new FileReader();
      r.readAsDataURL(file);
      r.onload = e => {
        file.url = file.thumbUrl = e.target.result;
      };
    }
    if (props.immediately) {
      window.clearTimeout(immediatelyTimeout);
      immediatelyTimeout = setTimeout(() => handleUpload(), 500);
    }
    // 判断是不是图片格式
    return false;
  };

  const uploading = ref(false);
  const handleUpload = (showMsg = true) => {
    if (!fileList.value.some(v => v instanceof File)) {
      return;
    }
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      fileList.value.forEach(file => {
        if (file instanceof File) {
          formData.append('file', file);
        }
      });
      Object.keys(props.data).map(key => {
        formData.append(key, props.data[key]);
      });
      uploading.value = true; // You can use any AJAX library you like
      (props.upload || upload)(formData).then(res => {
        showMsg && message.success('上传成功');
        if (res.data) {
          fileList.value = [...fileList.value.filter(v => !(v instanceof File)), ...strToObj(res.data)];
        } else {
          fileList.value = [...fileList.value.filter(v => !(v instanceof File))];
        }
        stopUpdate.value = true;
        emits('update:modelValue', objToStr(fileList.value));
        emits('afterUpload');
        resolve(true);
      }).catch(() => {
        message.error('上传失败');
        reject(false);
      }).finally(() => {
        uploading.value = false;
      });
    });
  };
  const visible = ref(false);
  const current = ref(0);
  const handlePreview = file => {
    if (attrs['list-type'] == 'picture-card') {
      current.value = fileList.value.indexOf(file);
      visible.value = true;
    } else if (file.url && regExpMap.get('URL').test(file.url)) {
      window.open(file.url);
    }
  };

  //向父级抛出的属性 方法
  defineExpose({
    upload: handleUpload,
    uploadReady,
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .my-upload {
    
  }
</style>
