<template>
  <my-select ref="mySelectRef" mode="multiple" :maxTagCount="99">
    <slot></slot>
  </my-select>
</template>

<script setup>
  import { ref, defineExpose } from 'vue';
  import mySelect from './index.vue';

  const mySelectRef = ref();
  const init = (...arg) => mySelectRef.value.init(...arg);

  //�򸸼��׳������� ����
  defineExpose({
    init
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
