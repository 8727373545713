<template>
  <a-switch :checked="props.modelValue == props.on" @update:checked="emits('update:modelValue', $event ? props.on : props.off)" @change="change"/>
</template>

<script setup>
  import { defineProps, defineEmits } from 'vue';
  const props = defineProps({
    modelValue: {
      type: [Boolean], 
    },
    on:{
      type: [Boolean], 
      default: true
    },
    off:{
      type: [Boolean], 
      default: false
    },
    show:{
      type: [Boolean], 
      default: true
    },
  });
  const emits = defineEmits(['update:modelValue', 'change']);

  const change = (value, event) => {
    emits('change', value ? props.on : props.off, event);
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
