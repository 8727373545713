<template>
  <a-button class="my-button" :class="buttonClass" :type="newType">
    <template #icon>
      <slot name="icon">
        <SearchOutlined v-if="props.type=='search'" />
        <sync-outlined v-if="props.type=='rest'" />
        <plus-outlined v-if="props.type=='add'" />
      </slot>
    </template>
    <slot>{{ buttonTitle }}</slot>
  </a-button>
</template>

<script setup>
  import { ref, defineProps, computed } from 'vue';
  import { SearchOutlined, SyncOutlined, PlusOutlined } from '@ant-design/icons-vue';
  const props = defineProps({
    type: {
      type: String,
      default: 'primary'
    }
  });
  const buttonClass = ref('');
  const buttonTitle = ref('');
  const newType = computed(() => {
    if (['primary', 'ghost', 'dashed', 'link', 'text', 'default'].indexOf(props.type) != -1) {
      return props.type;
    }
    const customTypeIndex = ['add', 'delete', 'update', 'import', 'export', 'download', 'search', 'rest', 'preview', 'approval'].indexOf(props.type);//自定义类型
    if (customTypeIndex != -1) {
      buttonClass.value = props.type;
      buttonTitle.value = ['新增', '删除', '编辑', '导入', '导出', '下载', '搜索', '重置', '查看', '审批'][customTypeIndex];
      return 'default'
    }
    return 'primary'
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .my-button {
    margin-right: 5px;
  }
  .add, .add:hover, .add:active, .add:focus {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
  }

  .delete, .delete:hover, .delete:active, .delete:focus {
    background-color: #ff0000;
    border-color: #ff0000;
    color: #fff;
  }

  .update, .update:hover, .update:active, .update:focus {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
  }

  .import, .import:hover, .import:active, .import:focus {
    background-color: #13ce66;
    border-color: #13ce66;
    color: #fff;
  }

  .export, .export:hover, .export:active, .export:focus {
    background-color: #ffba00;
    border-color: #ffba00;
    color: #fff;
  }

  .download, .download:hover, .download:active, .download:focus {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
  }

  .search, .search:hover, .search:active, .search:focus {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
  }
  .preview, .preview:hover, .preview:active, .preview:focus {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
  }
  .approval, .approval:hover, .approval:active, .approval:focus {
    background-color: #ff9900;
    border-color: #ff9900;
    color: #fff;
  }
  .add:hover,
  .delete:hover,
  .update:hover,
  .import:hover,
  .export:hover,
  .download:hover,
  .search:hover
  .add:focus,
  .delete:focus,
  .update:focus,
  .import:focus,
  .export:focus,
  .download:focus,
  .search:focus
  .preview:focus
  .approval:focus {
    opacity: 0.7;
  }
</style>
