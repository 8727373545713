import { Modal } from 'ant-design-vue';

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const PROJECT_NAME = 'settle';

export const getToken = () => localStorage.getItem(ACCESS_TOKEN);

export const setToken = token => localStorage.setItem(ACCESS_TOKEN, token);

const delHtmlTag = str => {
  return (str || '').replace(/<[^>]+>/g, '');
}
//转化表单项的placeholder 根据type类型拼接title
export const setPlaceholder = (type, title) => {
  if (['my-select'].indexOf(type) != -1) {
    return `请选择${ delHtmlTag(title) }`;
  } else if (['my-dateToDate', 'my-timeToTime'].indexOf(type) != -1) {
    return Array.isArray(title) ? [`${title[0]}`, `${title[1]}`] : [`${title}`, `${title}`];
  } else {
    return `请输入${ delHtmlTag(title) }`;
  }
}

export const regExpMap = new Map
  ([
    ['联系电话', /^1[23456789]\d{9}$/],//根据工信部2019年最新公布的手机号段
    ['座机电话', /\d{3}-\d{8}|\d{4}-\d{7}/],//如: 0341-86091234
    ['身份证', /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}([0-9]|x|X)$/],
    ['帐号', /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/],//字母开头，允许5-16字节，允许字母数字下划线组合
    ['纯中文', /^[\u4E00-\u9FA5]+$/],
    ['小数', /^\d+\.\d+$/],
    ['数字', /^\d{1,}$/],
    ['QQ号', /^[1-9][0-9]{4,10}$/],
    ['微信号', /^[a-zA-Z][-_a-zA-Z0-9]{5,19}$/],
    ['纯英文', /^[a-zA-Z]+$/],
    ['密码强度', /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/],//最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
    ['URL', /^((https?|ftp|file):\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/\w\.-]*)*\/?/],
    ['IPv4地址', /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/],
    ['16进制颜色', /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/],
    ['邮政编码', /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/],
    ['Email', /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/],
    ['isImg', /.(png|jpg|gif|jpeg|webp)$/],
    ['isVideo', /.(mp4|ogg|webm)$/],
    ['isSpace', /^[^\s]+(\s+[^\s]+)*$/],
    ['12位密码', /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#])[A-Za-z\d!@#]{12,}$/],//最少12位，密码应包含数字、大写字母、小写字母、符号（！、@、#、三种）4类
  ]);

/**
 * 验证生成器
 * @param {String} key 
 * @param {Boolean} required
 * @param {String} message 
 */
export const validateProvider = (key, required = false, message = "") => {
  let regExp = regExpMap.get(key);
  if (!regExp) throw new Error("没有找到对应的正则规则");

  const validator = (rule, value, callback) => {
    if (!value || regExp.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(message ? message : `请输入正确的${key}!`);
    }
  }
  return [{ validator }, { required }];
}

/**
 * 深拷贝
 * @param {*} obj 要拷贝的对象
 */
const deepClone = (obj, hash = new WeakMap()) => { //递归拷贝
  if (obj instanceof RegExp) return new RegExp(obj);
  if (obj instanceof Date) return new Date(obj);
  if (obj === null || typeof obj !== 'object') {
    //如果不是复杂数据类型，直接返回
    return obj;
  }
  if (hash.has(obj)) {
    return hash.get(obj);
  }
  /**
   * 如果obj是数组，那么 obj.constructor 是 [Function: Array]
   * 如果obj是对象，那么 obj.constructor 是 [Function: Object]
   */
  let t = new obj.constructor();
  hash.set(obj, t);
  for (let key in obj) {
    //如果 obj[key] 是复杂数据类型，递归
    if (obj.hasOwnProperty(key)) {//是否是自身的属性
      if (obj[key] && typeof obj[key] === 'object') {
        t[key] = deepClone(obj[key], hash);
      } else {
        t[key] = obj[key];
      }
    }
  }
  return t;
}

/**
 * @description 获取某个对象下的属性，用于通过类似'a.b.c'的形式去获取一个对象的的属性的形式
 * @param {object} obj 对象
 * @param {string} key 需要获取的属性字段
 * @returns {*}
 */
export function getProperty(obj, key) {
  if (!obj) {
    return
  }
  if (typeof key !== 'string' || key === '') {
    return ''
  }
  if (key.indexOf('.') !== -1) {
    const keys = key.split('.')
    let firstObj = obj[keys[0]] || {}

    for (let i = 1; i < keys.length; i++) {
      if (firstObj) {
        firstObj = firstObj[keys[i]]
      }
    }
    return firstObj
  }
  return obj[key]
}

/**
 * @description 设置对象的属性值，如果'a.b.c'的形式进行设置
 * @param {object} obj 对象
 * @param {string} key 需要设置的属性
 * @param {string} value 设置的值
 */
export function setProperty(obj, key, value) {
  if (!obj) {
    return
  }
  // 递归赋值
  const inFn = function (_obj, keys, v) {
    // 最后一个属性key
    if (keys.length === 1) {
      _obj[keys[0]] = v
      return
    }
    // 0~length-1个key
    while (keys.length > 1) {
      const k = keys[0]
      if (!_obj[k] || (typeof _obj[k] !== 'object')) {
        _obj[k] = {}
      }
      const key = keys.shift()
      // 自调用判断是否存在属性，不存在则自动创建对象
      inFn(_obj[k], keys, v)
    }
  }

  if (typeof key !== 'string' || key === '') {

  } else if (key.indexOf('.') !== -1) { // 支持多层级赋值操作
    const keys = key.split('.')
    inFn(obj, keys, value)
  } else {
    obj[key] = value
  }
}

//存储用户信息
export const setUserInfo = data => {
  if (!data || typeof data != 'object') {
    return;
  }
  localStorage.setItem(`${PROJECT_NAME}_userInfo`, JSON.stringify(data));
}

//获取用户信息
export const getUserInfo = key => {
  let userInfo = JSON.parse(localStorage.getItem(`${PROJECT_NAME}_userInfo`) || '{}');
  if (key) {
    return userInfo[key];
  }
  return userInfo;
}

//时间格式化
import dayjs from 'dayjs';
export const dateFormat = (time, format = 'YYYY/MM/DD') => {
  if (!time) {
    return '';
  }
  return dayjs(time).format(format);
}

export const showModal = (obj = {}) => {
  let config = {};
  if (typeof obj == 'string' || typeof obj == 'number') {
    config.content = obj;
  } else if (typeof obj == 'object') {
    config = { ...config, ...obj };
  }
  return new Promise((resolve, reject) => {
    Modal.confirm({
      title: '提示',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        resolve();
      },
      onCancel() {
        reject();
      },
      ...config
    });
  })
}

import JSEncrypt from 'jsencrypt'
export function encryptData(data) {
  let encrypt = new JSEncrypt();
  let pubKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCl797H5FuE/2qUNBayW5gjg271HHDrPbS5jibvEMyEoW/gXW12Qw2Hv7bV4BBFRytQR0wDShrhnw5Ttlu4QZYE0LVpHDwDblS5+q0IjAQiwzxC7QJi3Fer1O5Ur6JaThqfxZb6905WKNS+YysRrqhDdh1TDWuJ4Kjzt1wTVNHN4wIDAQAB'
  encrypt.setPublicKey(pubKey);
  return encrypt.encrypt(data);
}

/**
 * 金额数字转大写
 * @param {any} price
 */
export const digitUppercase = (price) => {
  const fraction = ['角', '分']
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ]
  if (price || price == 0) {
    let num = Math.abs(price)
    let s = ''
    fraction.forEach((item, index) => {
      s += (digit[Math.floor(num * 10 * (10 ** index)) % 10] + item).replace(/零./, '')
    })
    s = s || ''
    num = Math.floor(num)
    for (let i = 0; i < unit[0].length && num > 0; i += 1) {
      let p = ''
      for (let j = 0; j < unit[1].length && num > 0; j += 1) {
        p = digit[num % 10] + unit[1][j] + p
        num = Math.floor(num / 10)
      }
      s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
    }

    return s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^$/, '零元')
  }
}