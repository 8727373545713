<template>
  <!-- 下载 -->
  <my-modal title="下载" v-model="visibleUpload" :bodyStyle="{ paddingRight: '58px' }" :width="650">
    <a-spin :spinning="spinning" tip="加载中...">
      <div style="padding-bottom: 20px;">
        <a-button type="primary" @click="downloadFun()" :loading="loading">
          下载
        </a-button>
      </div>
      <div class="card">
        <div class="cardItem">
          <a-checkbox v-model:checked="checkAll"
                      :indeterminate="indeterminate"
                      class="fileCheck"
                      @change="onCheckAllChange">
          </a-checkbox>
          <span style="font-weight: 600;">文件名</span>
        </div>
        <div class="cardItem" v-for="(file, i) in configList" :key="'file' + i">
          <a-checkbox class="fileCheck" v-model:checked="file.checked"></a-checkbox>
          <a v-if="file.url" :href="file.url" target="_blank">{{ file.name }}</a>
          <span v-else>{{ file.name }}</span>
        </div>
      </div>
    </a-spin>
    <template #footer>
    </template>
  </my-modal>
</template>

<script setup>
  import { ref, defineProps, computed, defineComponent, watch } from 'vue';
  import { getFileDownloadList, attachmentDownload } from '@/api/public'
  import { message } from 'ant-design-vue';

  const props = defineProps({
    bizName: {
      type: String,
      default: ''
    }
  });
  // 上传
  const spinning = ref(false);
  const visibleUpload = ref(false);
  const configList = ref([])
  const applyId = ref('');
  const open = (id = '') => {
    id && (applyId.value = id);
    getFileDownloadList({ applyId: applyId.value, bizName: props.bizName }).then(res => {
      const temp = [];
      res.data.map(v => {
        temp.push(...v.fileList);
      });
      configList.value = temp;
    }).catch((e) => {
      message.error('获取附件列表失败！');
    })
    visibleUpload.value = true;
  }

  //全选
  const indeterminate = ref(false);
  const checkAll = ref(false);
  const onCheckAllChange = e => {
    configList.value.map(item => {
      item.checked = e.target.checked;
    });
    indeterminate.value = false;
  };
  watch(configList, () => {
    const val = configList.value.filter(v => v.checked);
    indeterminate.value = !!val.length && val.length < configList.value.length;
    checkAll.value = val.length === configList.value.length;
  }, { deep: true })

  const loading = ref(false);
  const downloadFun = () => {
    let data = {
      applyId: applyId.value,
      bizName: props.bizName,
      fileIdList: [],
      notStampedFileIdList: [],
    }
    configList.value.map(item => {
      if (item.checked && item.url) {
        data.fileIdList.push(item.id)
      } else if (item.checked) {
        data.notStampedFileIdList.push(item.id);
      }
    });
    loading.value = true;
    attachmentDownload(data).then(res => {
      if (res.headers['content-disposition']) {
        message.success(`下载成功`);
        //获取文件名称与文件类型
        const filename = decodeURI(res.headers['content-disposition'].split('filename=')[1].split('.')[0]);
        const contentType = res.headers['content-type'];
        const url = window.URL.createObjectURL(new Blob([res.data], {
          type: contentType
        }));
        const link = document.createElement('a');
        link.style.display = "none";
        link.download = filename;
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        const reader = new FileReader()
        reader.onload = async () => {
          const err = JSON.parse(reader.result);
          message.error(err?.msg || `下载失败`);
        }
        reader.readAsText(res.data);
      }
    }).catch((e) => {
      console.log(e);
      message.error('下载失败');
    }).finally(() => {
      loading.value = false;
    });
  }

  //向父级抛出的属性 方法
  defineExpose({
    open
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .card {
    width: 600px;
    border: 1px solid rgba(121, 121, 121, 0.3);
    border-bottom: 0px;
  }
  .cardItem {
    border-bottom: 1px solid rgba(121, 121, 121, 0.3);
    padding: 10px;
  }
  .fileCheck {
    margin-right: 10px;
  }
</style>
