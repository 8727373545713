<template>
  <a-select class="my-select" :value="props.modelValue" @update:value="emits('update:modelValue', $event)" :options="option?option:props.options" allowClear>
    <slot></slot>
  </a-select>
</template>

<script setup>
  import { ref, defineProps, defineEmits, defineExpose, watchEffect } from 'vue';
  const props = defineProps({
    modelValue: {},
    url: {
      type: [Function], 
    },
    params: {
      type: [Object],
      default: () => ({})
    },
    onSetOptions: {
      type: [Function],
    },
    options: {
      type: [Array],
      default: () => []
    },
    show: {
      type: [Boolean],
      default: true,
    }
  });
  const emits = defineEmits(['update:modelValue']);
  const option = ref(false);
  const init = (fun = props.url, params = props.params) => {
    fun(params).then(res => {
      if (props.onSetOptions) {
        option.value = props.onSetOptions(res);
      } else {
        option.value = res.data.records ? res.data.records : res.data;
      }
    })
  };
  watchEffect(() => {
    props.show && props.url && init(props.url, props.params);
  });

  defineExpose({
    init,
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .my-select{
    min-width: 200px;
  }
</style>
