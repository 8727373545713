import { createRouter, createWebHistory } from "vue-router";
import { getToken } from "@/utils";
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/login",
      name: "loginPage",
      meta: { title: "loginPage" },
      component: () => import("@/views/login/index.vue"),
    },
    {
      path: "/",
      name: "homePage",
      meta: { title: "home" },
      component: () => import("@/views/home/index.vue"),
      children: [
        {
          path: "notice",
          meta: { title: "系统通知" },
          component: () => import("@/views/notice/system/index.vue"),
        }
      ]
    },
    // 修改密码
    {
      path: "/changePW",
      name: "changePW",
      meta: { title: "changePW" },
      component: () => import("@/views/changePW/index.vue"),
    },
    // 测试页面
    {
      path: "/test",
      name: "test",
      meta: { title: "test" },
      component: () => import("@/views/test/index.vue"),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'errorPage',
      meta: { hideInMenu: true },
      component: () => import('@/views/error/404.vue')
    }
  ],
});

//处理路由数据
const getRouter = () => {
  const routers = JSON.parse(localStorage.getItem('router')) || [];
  const newRouter = [];
  routers.map((v) => {
    if (v.route) {
      newRouter.push({
        path: v.route,
        name: v.name || v.route.slice(1),
        component: () => import(`@/${v.component}`),
        meta: { ...(v.meta || {}), title: v.title }
      });
    } else if (v.children.length) {
      v.children.map(menu => {
        newRouter.push({
          path: menu.route,
          name: menu.name || menu.route.slice(1),
          component: () => import(`@/${menu.component}`),
          meta: { ...(menu.meta || {}), title: menu.title }
        });
      })
    }
  });
  return newRouter;
};

let doAddRoute = false;
router.beforeEach((to, from, next) => {
  const token = getToken();
  if (!token && to.path !== "/login" && to.path !== "/changePW") {
    next("/login");
  } else if (token && to.path == '/login') {
    next('/');
  } else if (token && !doAddRoute) {
    console.log('组装路由');
    doAddRoute = true;
    const addRouter = getRouter();
    addRouter.length && addRouter.map((v) => router.addRoute('homePage', v));
    next(to.path);
  } else {
    next();
  }
});

router.afterEach(() => {
  console.log("routerAfterEach");
});

export default router;
