<template>
  <my-tree-select @update:modelValue="emits('update:modelValue', $event.map(v => v.value))" ref="myNode" multiple :maxTagCount="99" treeCheckable treeCheckStrictly>
    <slot></slot>
  </my-tree-select>
</template>

<script setup>
  import { ref, defineExpose } from 'vue';
  import myTreeSelect from './index.vue';
  const emits = defineEmits(['update:modelValue']);
  const myNode = ref();
  const init = (...arg) => myNode.value.init(...arg);
  
  //向父级抛出的属性 方法
  defineExpose({
    init
  });
</script>

<style scoped>
  
</style>
