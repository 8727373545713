<template>
  <a-drawer :visible="props.modelValue" @update:visible="emits('update:modelValue', $event)" :closable="false" :bodyStyle="{ padding: 0 }" :footerStyle="{ textAlign: 'right' }" @close="onClose">
    <div ref="myDrawer" class="my-drawer-body" :style="newBodyStyle">
      <slot></slot>
    </div>
    <template #footer v-if="Object.keys($slots).some((v) => v === 'footer')">
      <slot name="footer"></slot>
    </template>
    <template #extra>
      <slot name="extra">
        <a-space>
          <close-outlined @click="onClose" />
        </a-space>
      </slot>
    </template>
  </a-drawer>
</template>
<script setup>
  import { defineProps, defineEmits, computed, watch, ref, nextTick } from "vue";
  import { CloseOutlined } from "@ant-design/icons-vue";

  const props = defineProps({
    modelValue: {
      type: Boolean,
    },
    bodyStyle: {
      type: Object,
      dafault: () => ({}),
    },
  });

  const emits = defineEmits(["update:modelValue", 'close']);

  const newBodyStyle = computed(() => {
    return Object.assign({}, props.bodyStyle);
  });

  const onClose = () => {
    emits("update:modelValue", false);
    emits("close", false);
  };
  const myDrawer = ref();

  watch(() => props.modelValue, (v) => {
    nextTick(() => {
      myDrawer.value.scrollTop = 0;
    });
  }
  );

  defineExpose({
    myModal: myDrawer,
  });

</script>

<style scoped>
  .my-drawer-body {
    padding: 20px;
    height: 100%;
    overflow: auto;
  }
</style>