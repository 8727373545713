<template>
  <div class="editorBorder" v-if="!props.disabled">
    <Toolbar style="border-bottom: 1px solid #ccc"
             :editor="editorRef"
             :defaultConfig="toolbarConfig"
             mode="default" />
    <Editor style="height: 500px; overflow-y: hidden;"
            v-model="value"
            :defaultConfig="editorConfig"
            mode="default"
            @onCreated="handleCreated"
            @onChange="handleChange" />
  </div>
  <div v-else class="editorBorder editorPreview" v-html="props.modelValue" @click="preview"></div>
  <div style="display:none;">
    <a-image :preview="{ visible, onVisibleChange: vis => (visible = vis) }" :src="previewSrc" />
  </div>
</template>

<script>
  // declare additional options
  export default {
    inheritAttrs: false,
  }
</script>

<script setup>
  import '@wangeditor/editor/dist/css/style.css' // 引入 css
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import { ref, defineProps, defineEmits, defineExpose, watchEffect, watch, onBeforeUnmount, shallowRef, nextTick } from 'vue';
  import { upload } from '@/api/public';
  import { message } from 'ant-design-vue';
  import { regExpMap } from '@/utils';
  const props = defineProps({
    modelValue: {},
    disabled: {
      type: [Boolean],
      default: false,
    },
    show: {
      type: [Boolean],
      default: true,
    },
  });
  const emits = defineEmits(['update:modelValue']);
  // 编辑器实例，必须用 shallowRef
  const editorRef = shallowRef()
  const value = ref('');
  const toolbarConfig = {}
  const editorConfig = {
    placeholder: '请输入内容...',
    readOnly: props.disabled,
    'MENU_CONF': {
      uploadImage: {
        // 自定义上传
        async customUpload(file, insertFn) {
          // file 即选中的文件
          if (!regExpMap.get('isImg').test(file.type)) {
            message.error('请选择正确的图片格式');
            return;
          }
          if (file.size > 5 * 1024 * 1024) {
            message.error('图片大小必须小于5M');
            return;
          }
          const formData = new FormData();
          formData.append('file', file);
          upload(formData).then(res => {
            message.success('上传成功');
            // 最后插入图片
            if (res.data && res.data[0].url) {
              insertFn(res.data[0].url, file.name);//第三个参数是跳转地址
            }
          }).catch(() => {
            message.error('上传失败');
          });
        }
      },
      uploadVideo: {
        async customUpload(file, insertFn) {
          // file 即选中的文件
          console.log(file);
          if (!regExpMap.get('isVideo').test(file.type)) {
            message.error('请选择正确的图片格式');
            return;
          }
          if (file.size > 100 * 1024 * 1024) {
            message.error('视频大小必须小于100M');
            return;
          }
          const formData = new FormData();
          formData.append('file', file);
          upload(formData).then(res => {
            message.success('上传成功');
            // 最后插入图片
            if (res.data && res.data[0]) {
              // 最后插入视频
              insertFn(res.data[0].url);
            }
          }).catch(() => {
            message.error('上传失败');
          });
        }
      },
    }
  };//
  let stop = false;
  watch(() => props.modelValue, v => {
    if (stop) {
      stop = false;
      return;
    }
    if (!props.modelValue) {
      value.value = '';
      nextTick(() => { editorRef.value?.clear(); });
    } else if (props.modelValue && props.modelValue.indexOf('<p>') == -1) {
      value.value = `<p>${props.modelValue}</p>`;
    } else {
      value.value = props.modelValue;
    }
  }, { immediate: true });

  watch(() => props.disabled, v => {
    if (!editorRef.value) {
      return;
    }
    v ? editorRef.value.disable() : editorRef.value.enable();
  }, { immediate: true });

  const handleChange = (editor) => {
    //console.log('change:', editor.children);
    //console.log('change:', value.value);
    if (editor.isEmpty() || isNull(value.value)) {
      //console.log('change:空数据');
      emits('update:modelValue', '');
    } else {
      stop = props.modelValue != value.value;
      emits('update:modelValue', value.value);
    }
  }

  watchEffect(() => {
    props.show && (value.value = undefined);
  });

  const handleCreated = (editor) => {
    editorRef.value = editor // 记录 editor 实例，重要！
  }

  const isNull = str => {
    const newStr = str.replace(/<[^<p>]>/g, '').replace(/<[</p>$]+>/g, '').replace(/<[<br/>]+>/g, '').replace(/&nbsp;/gi, '');
    //console.log(newStr);
    if (newStr == '') {
      return true;
    }
    const re = new RegExp('^[ ]+$');
    return re.test(newStr);
  }

  // 组件销毁时，也及时销毁编辑器
  onBeforeUnmount(() => {
    const editor = editorRef.value
    if (editor == null) return
    editor.destroy()
  });

  const visible = ref(false);
  const previewSrc = ref('');
  const preview = e => {
    //console.log(e.target.tagName, e.target.localName);
    //console.log(e.target);
    if (e.target.tagName == 'IMG') {
      previewSrc.value = e.target.src;
      visible.value = true;
      nextTick(() => {
        document.getElementsByClassName('ant-image-preview-img')[0].onclick = () => {
          visible.value = false;
        }
      });
    }
  }

  //向父级抛出的属性 方法
  defineExpose({

  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .editorBorder {
    border: 1px solid #ccc;
  }
  .editorPreview /deep/ img, .editorPreview /deep/ video {
    max-width: 100%;
  }
</style>
