<template>
  <div class="my-text">{{ format() }}</div>
</template>

<script setup>
  import { defineProps } from 'vue';
  import { dateFormat } from '@/utils';
  const props = defineProps({
    modelValue: {
      type: [String, Number], 
    },
    mode: {
      type: [String], 
    },
    format: {
      type: [Function],
    }
  });

  const format = () => {
    if (props.format) {
      return props.format(props.modelValue);
    }
    if (!props.mode) {
      return props.modelValue;
    }
    if (props.mode == 'time') {
      return dateFormat(props.modelValue, 'YYYY/MM/DD HH:mm:ss');
    }
    if (props.mode == 'date') {
      return dateFormat(props.modelValue, 'YYYY/MM/DD');
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .my-text {
    display: inline-block;
  }
</style>
