import request from "@/utils/request";

const base = '/sys-user';

export function page(data) {
  return request({
    url: `${base}/list`,
    method: "get",
    params: data,
  });
}

export function pageByID({ userId }) {
  return request({
    url: `${base}/detail`,
    method: 'get',
    params: { id: userId }
  })
}

export function save(data) {
  return request({
    url: `${base}/add`,
    method: 'post',
    data: data
  })
}

export function update(data) {
  data.id = data.userId;
  const copy = {...data}
  delete copy.passwordA
  return request({
    url: `${base}/modify`,
    method: 'post',
    data: copy
  })
}

export function batchDelete(data) {
  return request({
    url: `${base}/del`,
    method: 'post',
    data: data
  })
}

//获取用户对应的角色
export function getUserRole({ id }) {
  return request({
    url: `${base}/role`,
    method: 'get',
    params: { id }
  })
}

//获取用户信息
export function userInfo() {
  return request({
    url: `${base}/info`,
    method: 'get',
  })
}

//为用户绑定角色
export function bindRole(data) {
  return request({
    url: `${base}/bind-role`,
    method: 'post',
    data: data
  })
}

export function updateStatus(data) {
  data.id = data.userId;
  return request({
    url: `${base}/update-account-status`,
    method: 'get',
    params: data
  })
}

//经销商下拉（全部）
export function getDealerSelect() {
  return request({
    url: `/dealer-credit-quota/dealer-select-all`,
    method: 'get',
  })
}