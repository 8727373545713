import request from '@/utils/request'

export function getVerificationCode() {
  return request({
    url: '/security/obtain-graph-captcha',
    method: 'get'
  })
}

export function login (data) {
  return request({
    //url: '/login.json',
    url: '/login/username_password',
    method: 'post',
    data: data
  })
}

export function getMenu() {
  return request({
    //url: '/menu.json',
    url: '/sys-menu/obtain-menu',
    method: 'get',
  })
}

export function getCheck(data) {
  return request({
    url: '/success.json',
    method: 'post',
    data: data
  })
}

// 修改密码
export function modifyPassword(data) {
  return request({
    url: '/sys-user-account/modify-password',
    method: 'post',
    data: data
  })
}

// 重置密码
export function resetPassword(data) {
  return request({
    url: '/sys-user/reset-password',
    method: 'post',
    data: data
  })
}

// 检查密码
export function checkPassword(params) {
  return request({
    url: '/sys-user/check-password',
    method: 'get',
    params
  })
}

// 用户管理 点修改 --重置密码
export function adminResetPassword(params) {
  return request({
    url: '/sys-user/reset-password-admin',
    method: 'get',
    params
  })
}