<template>
  <my-upload ref="nodeRef" :modelValue="value" @update:modelValue="value = $event" list-type="picture-card" accept="image/*" :subitemType="props.subitemType">
    <slot></slot>
  </my-upload>
</template>

<script setup>
  import { ref, defineProps, defineEmits, defineExpose, watch } from 'vue';
  import myUpload from './index.vue';
  import { subitemType } from './config.js'
  const props = defineProps({
    modelValue: {},
    subitemType: {
      type: String,
      default: subitemType
    },
  });
  const value = ref([]);
  const emits = defineEmits(['update:modelValue']);
  let stop = false;
  watch(() => props.modelValue, v => {
    if (stop) {
      stop = false;
      return;
    }
    if (props.modelValue === undefined || props.modelValue === null) {
      value.value = [];
    } else if (typeof (props.modelValue) == 'string') {
      value.value = props.modelValue.split(',');
    } else if (Array.isArray(props.modelValue)) {
      value.value = [...props.modelValue];
    }
  }, { deep: true, immediate: true });

  watch(value, v => {
    stop = true;
    if (props.subitemType == 'object') {
      emits('update:modelValue', v);
    } else if (typeof (props.modelValue) == 'string' && Array.isArray(v)) {
      emits('update:modelValue', v.join(','));
    } else {
      emits('update:modelValue', v);
    }
  });

  const nodeRef = ref();
  const upload = (...arg) => nodeRef.value.upload(...arg);
  const uploadReady = ref();

  watch(() => nodeRef.value?.uploadReady, v => {
    uploadReady.value = nodeRef.value.uploadReady
  });

  //向父级抛出的属性 方法
  defineExpose({
    upload,
    uploadReady,
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
